/**
 * Vuetify config
 *
 * @author Jaimyn Mayer
 */

import { customIcons } from 'js/customIcons';
import Vuetify from 'vuetify/lib';

const vuetifyThemeOptions = {
    dark: false,
    themes: {
        light: {
            primary: '#338eca',
            secondary: '#ff5722',
            accent: '#2196f3',
            topBarBg: '#fafafa',
        },
        dark: {
            primary: '#338eca',
            secondary: '#ff5722',
            accent: '#2196f3',
            topBarBg: '#fafafa',
        },
    },
};

export default new Vuetify({
    theme: vuetifyThemeOptions,
    icons: {
        iconfont: 'faSvg',
        values: customIcons,
    },
});
