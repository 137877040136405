/* eslint-disable unicorn/prevent-abbreviations */
// import Vue from "vue";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faVuejs } from '@fortawesome/free-brands-svg-icons';
import {
    faArrowAltToLeft,
    faArrowAltToRight,
    faArrowToLeft,
    faArrowToRight,
    faClock,
    faCogs as outlineCogs,
    faColumns,
    faDownload as outlineDownload,
    faExchangeAlt,
    faExpand,
    faExpandArrowsAlt,
    faExpandWide,
    faFileExport as fileExportReg,
    faFilePdf,
    faInfoCircle as faInfoCircleReg,
    faLink,
    faQuestion,
    faRobot as outlineRobot,
    faSquare,
    faStar as hollowStar,
    faTimes,
    faUnlink,
    faUserAlt as outlinePerson,
    faPaperPlane,
    faCircle as radioOff,
    faDotCircle as radioOn
} from '@fortawesome/pro-regular-svg-icons';
import {
    faAlignLeft,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowsAltV,
    faArrowsV,
    faArrowUp,
    faBars,
    faBinoculars,
    faBraille,
    faBriefcase,
    faBuilding,
    faCalendarAlt,
    faCaretDown,
    faCaretUp,
    faChartArea,
    faChartScatter,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCity,
    faClipboard,
    faClipboardCheck,
    faClipboardList,
    faCogs,
    faCopy,
    faCube,
    faDownload,
    faDraftingCompass,
    faDrawCircle,
    faDrawPolygon,
    faDrawSquare,
    faEllipsisV,
    faEnvelope,
    faExclamation,
    faExclamationCircle,
    faEye,
    faEyeSlash,
    faFileExport,
    faFileImport,
    faFillDrip,
    faFingerprint,
    faGlobeAsia,
    faHome,
    faHorizontalRule,
    faIdCard,
    faImage,
    faImages,
    faInfoCircle,
    faList,
    faLock,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faMinusSquare,
    faMobile,
    faMonitorHeartRate,
    faMountains,
    faMousePointer,
    faPalette,
    faPaperclip,
    faPen,
    faPhone,
    faPlus,
    faPlusSquare,
    faQuestion as solidQuestion,
    faQuestionCircle,
    faRedo,
    faRobot,
    faRuler,
    faRulerTriangle,
    faSearch,
    faShieldAlt,
    faSignIn,
    faSignOut,
    faSlidersHSquare,
    faSortSizeDown,
    faSortSizeUp,
    faStar as solidStar,
    faStream,
    faSun,
    faTag,
    faThermometerQuarter,
    faToggleOn,
    faTools,
    faTrashAlt,
    faUndo,
    faUpload,
    faUserAlt,
    faUserCircle,
    faUserFriends,
    faUserLock,
    faUserPlus,
    faUsers,
    faWallet
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
    faRedo,
    faUndo,
    faStream,
    outlineRobot,
    outlinePerson,
    faUserAlt,
    faRobot,
    faSortSizeUp,
    faSortSizeDown,
    faSlidersHSquare,
    faChartScatter,
    faThermometerQuarter,
    faFingerprint,
    faBars,
    faSignIn,
    faSignOut,
    faUserPlus,
    faUserCircle,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faHome,
    faCity,
    faVuejs,
    faExclamationCircle,
    faCheckCircle,
    faExclamation,
    faInfoCircle,
    faCaretDown,
    faUsers,
    faList,
    faUpload,
    faCogs,
    faMonitorHeartRate,
    faTools,
    faQuestionCircle,
    faTimes,
    faUserFriends,
    faAlignLeft,
    faImage,
    faEnvelope,
    faPen,
    faUserLock,
    faClock,
    faCalendarAlt,
    faEye,
    faEyeSlash,
    faLock,
    faShieldAlt,
    faChevronLeft,
    faArrowUp,
    faArrowRight,
    faEllipsisV,
    faCheckSquare,
    faSquare,
    faCheck,
    faEye,
    faEyeSlash,
    faLock,
    faShieldAlt,
    faWallet,
    faCity,
    faBriefcase,
    faCheckSquare,
    faSquare,
    faCheck,
    faEye,
    faEyeSlash,
    faLock,
    faShieldAlt,
    faWallet,
    faCity,
    faBriefcase,
    faPlus,
    faPlusSquare,
    faMinusSquare,
    faIdCard,
    faCheckSquare,
    faSquare,
    faCheck,
    faEye,
    faEyeSlash,
    faLock,
    faShieldAlt,
    faWallet,
    faCity,
    faBriefcase,
    faBuilding,
    faChevronRight,
    faColumns,
    faArrowToRight,
    faArrowToLeft,
    faExpandWide,
    faCaretUp,
    faGlobeAsia,
    faBuilding,
    faBinoculars,
    faSun,
    faArrowsAltV,
    faMousePointer,
    faToggleOn,
    faTag,
    faFileExport,
    faFileImport,
    faHorizontalRule,
    faDrawPolygon,
    faDrawSquare,
    faDrawCircle,
    faRuler,
    faRulerTriangle,
    faCube,
    faPalette,
    faFillDrip,
    faDownload,
    faImages,
    faTrashAlt,
    faPaperclip,
    solidQuestion,
    faSquare,
    faClock,
    faColumns,
    faArrowToRight,
    faArrowToLeft,
    faExpandWide,
    faArrowAltToLeft,
    faArrowAltToRight,
    faQuestion,
    faFilePdf,
    faLink,
    faTimes,
    faExpand,
    faUnlink,
    faExchangeAlt,
    faQuestion,
    faArrowDown,
    faArrowLeft,
    faSearch,
    faClipboardList,
    faChevronUp,
    faChevronDown,
    solidStar,
    hollowStar,
    faClipboard,
    faClipboardCheck,
    faCopy,
    faArrowsV,
    faExpandArrowsAlt,
    faChartArea,
    fileExportReg,
    faInfoCircleReg,
    faDraftingCompass,
    faBraille,
    faMountains,
    faPhone,
    faMobile,
    outlineDownload,
    outlineCogs,
    faPaperPlane,
    radioOff,
    radioOn
);

// eslint-disable-next-line import/prefer-default-export
export const customIcons = {
    redo: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'redo']
        }
    },
    paperPlane: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'paper-plane']
        }
    },
    reset: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'undo']
        }
    },
    celery_queue: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'stream']
        }
    },
    person: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'user-alt']
        }
    },
    outlinePerson: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'user-alt']
        }
    },
    outlineRobot: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'robot']
        }
    },
    robot: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'robot']
        }
    },
    sortSizeUp: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'sort-size-up']
        }
    },
    sortSizeDown: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'sort-size-down']
        }
    },
    sliders: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'sliders-h-square']
        }
    },
    shotPlot: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'chart-scatter']
        }
    },
    thermometer: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'thermometer-quarter']
        }
    },
    copied: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'clipboard-check']
        }
    },
    copy: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'copy']
        }
    },
    clipboard: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'clipboard']
        }
    },
    fingerprint: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fingerprint']
        }
    },
    claim: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'clipboard-list']
        }
    },
    chevronDown: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'chevron-down']
        }
    },
    chevronUp: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'chevron-up']
        }
    },
    search: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'search']
        }
    },
    sort: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'arrow-up']
        }
    },
    swapViews: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'exchange-alt']
        }
    },
    helpTopMenu: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'question']
        }
    },
    unSync: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'unlink']
        }
    },
    expandView: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'expand']
        }
    },
    close: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'times']
        }
    },
    clear: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'times']
        }
    },
    sync: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'link']
        }
    },
    report: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'file-pdf']
        }
    },
    inspectionUp: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'arrow-up']
        }
    },
    inspectionDown: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'arrow-down']
        }
    },
    inspectionLeft: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'arrow-left']
        }
    },
    inspectionRight: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'arrow-right']
        }
    },
    openOnLeft: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'arrow-alt-to-left']
        }
    },
    openOnRight: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'arrow-alt-to-right']
        }
    },
    cesiumTilesView: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'building']
        }
    },
    bimView: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'drafting-compass']
        }
    },
    pointCloudView: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'braille']
        }
    },
    terrainView: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'mountains']
        }
    },
    inspectionView: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'binoculars']
        }
    },
    orthoView: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'globe-asia']
        }
    },
    fullScreenComparison: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'expand-wide']
        }
    },
    arrowToRight: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'arrow-to-right']
        }
    },
    arrowToLeft: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'arrow-to-left']
        }
    },
    columns: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'columns']
        }
    },
    prev: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'arrow-left']
        }
    },
    next: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'arrow-right']
        }
    },
    complete: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'check']
        }
    },
    checkboxOff: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'square']
        }
    },
    checkboxOn: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'check-square']
        }
    },
    moreOptions: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'ellipsis-v']
        }
    },
    arrowUp: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'arrow-up']
        }
    },
    chevronLeft: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'chevron-left']
        }
    },
    chevronRight: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'chevron-right']
        }
    },
    menu: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'bars']
        }
    },
    help: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'question']
        }
    },
    tools: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'tools']
        }
    },
    teams: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'users']
        }
    },
    sites: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'list']
        }
    },
    settings: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'cogs']
        }
    },
    settingsOutline: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'cogs']
        }
    },
    upload: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'upload']
        }
    },
    tests: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'monitor-heart-rate']
        }
    },
    dropdown: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'caret-down']
        }
    },
    dropdownUp: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'caret-up']
        }
    },
    expand: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'caret-down']
        }
    },
    error: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'exclamation-circle']
        }
    },
    success: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'check-circle']
        }
    },
    warning: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'exclamation']
        }
    },
    info: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'info-circle']
        }
    },
    login: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'sign-in']
        }
    },
    logout: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'sign-out']
        }
    },
    register: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'user-plus']
        }
    },
    profile: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'user-circle']
        }
    },
    home: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'home']
        }
    },
    city: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'city']
        }
    },
    mapview: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'map-marked-alt']
        }
    },
    mapMarkerAlt: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'map-marker-alt']
        }
    },
    team: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'user-friends']
        }
    },
    details: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'align-left']
        }
    },
    image: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'image']
        }
    },
    email: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'envelope']
        }
    },
    edit: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'pen']
        }
    },
    userLock: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'user-lock']
        }
    },
    clock: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'clock']
        }
    },
    calendar: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'calendar-alt']
        }
    },
    eye: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'eye']
        }
    },
    eyeSlash: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'eye-slash']
        }
    },
    lock: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'lock']
        }
    },
    shield: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'shield-alt']
        }
    },
    wallet: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'wallet']
        }
    },
    companyAddress: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'city']
        }
    },
    briefCase: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'briefcase']
        }
    },
    building: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'building']
        }
    },
    plus: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'plus']
        }
    },
    plusSquare: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'plus-square']
        }
    },
    minusSquare: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'minus-square']
        }
    },
    id: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'id-card']
        }
    },
    sun: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'sun']
        }
    },
    upDownArrow: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'arrows-alt-v']
        }
    },
    mouse: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'mouse-pointer']
        }
    },
    toggle: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'toggle-on']
        }
    },
    label: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'tag']
        }
    },
    export: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'file-export']
        }
    },
    import: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'file-import']
        }
    },
    line: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'horizontal-rule']
        }
    },
    drawPolygon: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'draw-polygon']
        }
    },
    drawSquare: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'draw-square']
        }
    },
    drawCircle: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'draw-circle']
        }
    },
    ruler: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'ruler']
        }
    },
    triangleRuler: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'ruler-triangle']
        }
    },
    invertedTriangleRuler: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'ruler-triangle'],
            flip: 'vertical'
        }
    },
    cube: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'cube']
        }
    },
    colors: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'palette']
        }
    },
    paint: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'fill-drip']
        }
    },
    download: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'download']
        }
    },
    images: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'images']
        }
    },
    delete: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'trash-alt']
        }
    },
    file: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'paperclip']
        }
    },
    solidStar: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'star']
        }
    },
    hollowStar: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'star']
        }
    },
    altitude: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'arrows-v']
        }
    },
    expandArrows: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'expand-arrows-alt']
        }
    },
    chartArea: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'chart-area']
        }
    },
    fileExportReg: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'file-export']
        }
    },
    infoCircleReg: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'info-circle']
        }
    },
    phone: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'phone']
        }
    },
    mobile: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'mobile']
        }
    },
    outlineDownload: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'download']
        }
    },
    pdf: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'file-pdf']
        }
    },
    addUser: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'user-plus']
        }
    },
    radioOff: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'circle']
        }
    },
    radioOn: {
        component: FontAwesomeIcon,
        props: {
            icon: ['far', 'dot-circle']
        }
    }
};
/* eslint-enable unicorn/prevent-abbreviations */
